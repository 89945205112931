import { Space, Skeleton } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import BillingAddressCard from "../../../Components/Cards/BillingAddressCard";
import ItemCard from "../../../Components/Cards/ItemCard";
import OrderCard from "../../../Components/Cards/OrderCard";
import ShippingAddressCard from "../../../Components/Cards/ShippingAddressCard";
import useRequest from "../../../Hooks/useRequest";
import { OrderDetail as OrderDetailService } from "../../../Service/order";


function OrdeDetail() {
  const { orderNumber } = useParams();
  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(OrderDetailService, [orderNumber]);
  return (
    <Skeleton paragraph={{ rows: 6 }} loading={status === "pending"}>
      <Space direction="vertical" style={{minWidth : "100%"}}>
        <OrderCard {...data} updateOrder={() => update()}/>
        <BillingAddressCard
          loading={status === "pending"}
          {...(data?.billingAddresses && data?.billingAddresses[0])}
        />
        <ShippingAddressCard
          loading={status === "pending"}
          {...(data?.shippingAddresses && data?.shippingAddresses[0])}
        />
        <ItemCard items={data?.items || []} data={data} updateOrder={() => update()} />
      </Space>
    </Skeleton>
  );
}

export default OrdeDetail;
