import { Button, Collapse, Table, Row, Col } from "antd";
import { DownloadOutlined } from "@ant-design/icons/lib/icons";
import useRequest from "../../Hooks/useRequest";
import { formatDate } from "../../utils/date";
import { formatPrice } from "../../utils/price";
import Filters from "./Filters";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {
  periodicSalesReport,
  periodicSalesReportExport
} from "../../Service/report";
import { downloadFile } from "../../Service";
import usePagination from "../../Hooks/usePagination";
import { notify } from "../../utils/notify";
import {useEffect, useState} from "react";
import moment from "moment/moment";

const { Panel } = Collapse;
const PeriodicSalesReportTable = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search)
  const [initialValues, setInitialValues] = useState({});
  const [params, setParams] = usePagination({
    ProductName: "",
    ProductSerialNumber: "",
    StartDate: "",
    EndDate: "",
    OrderStartCreatedDate: "",
    OrderEndCreatedDate: "",
    OrderNumber: undefined,
  });
  const navigate = useNavigate();

  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(periodicSalesReport, [params]);



  useEffect(() => {
    const data = {};
    for (const [key, value] of Object.entries(params)) {
      if(String(query?.get(key)) !== "undefined" && String(query?.get(key)) !== "null"){
        data[key] = query?.get(key)
      }
    }

    setInitialValues({
      ...data,
      StartDate: data.StartDate ? moment(data.StartDate,"YYYY-MM-DD") : undefined,
      EndDate: data.EndDate ? moment(data.EndDate,"YYYY-MM-DD") : undefined,
      OrderStartCreatedDate: data.OrderStartCreatedDate ? moment(data.OrderStartCreatedDate,"YYYY-MM-DD") : undefined,
      OrderEndCreatedDate: data.OrderEndCreatedDate ? moment(data.OrderEndCreatedDate,"YYYY-MM-DD") : undefined,
    })
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setParams({
        ...params,
        ...initialValues,
        StartDate: initialValues.StartDate ? formatDate(initialValues.StartDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
        EndDate: initialValues.EndDate ? formatDate(initialValues.EndDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
        OrderStartCreatedDate: initialValues.OrderStartCreatedDate ? formatDate(initialValues.OrderStartCreatedDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
        OrderEndCreatedDate: initialValues.OrderEndCreatedDate ? formatDate(initialValues.OrderEndCreatedDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
      })
    },400)
  }, [initialValues]);

  const Columns = [
    {
      key: "productName",
      title: "Ürün Adı",
      dataIndex: "productName",
    },
    {
      key: "serialNumber",
      title: "Seri Numarası",
      dataIndex: "serialNumber",
    },
    {
      key: "orderNumber",
      title: "Sipariş Numarası",
      dataIndex: "orderNumber",
    },
    {
      key: "startDate",
      title: "Başlangıç Tarihi",
      dataIndex: "startDate",
      render: (date) => formatDate(date),
    }, 
    {
      key: "endDate",
      title: "Bitiş Tarihi",
      dataIndex: "endDate",
      render: (date) => formatDate(date),
    },
    {
      key: "priceTypeText",
      title: "Sipariş Süresi",
      dataIndex: "priceTypeText",
    },
    {
      key: "orderStatus",
      title: "Sipariş Durumu",
      dataIndex: "orderStatus",
    },
    {
      key: "paymentStatus",
      title: "Ödeme Durumu",
      dataIndex: "paymentStatus",
    },
    {
      key: "price",
      title: "Fiyat",
      dataIndex: "price",
      render: (price) => formatPrice(price), 
    },
    {
      key: "reportStartDate",
      title: "Rapor Başlangıç Tarihi",
      dataIndex: "reportStartDate",
      render: (date) => formatDate(date),
    },
    {
      key: "reportEndDate",
      title: "Rapor Bitiş Tarihi",
      dataIndex: "reportEndDate",
      render: (date) => formatDate(date),
    },
    {
      key: "fullName",
      title: "Ad Soyad",
      dataIndex: "fullName",
    },
    {
      key: "phone",
      title: "Telefon",
      dataIndex: "phone",
    },
    {
      key: "insurancePrice",
      title: "Güvence Bedeli",
      dataIndex: "insurancePrice",
      render: (price) => formatPrice(price), 
    },
    {
      key: "orderCreatedDate",
      title: "Sipariş Oluşturma Tarihi",
      dataIndex: "orderCreatedDate",
      render: (date) => formatDate(date),
    },
    {
      key: "customerId",
      title: "Müşteri ID",
      dataIndex: "customerId",
    },
    {
      key: "cityName",
      title: "İl",
      dataIndex: "cityName",
    },
    {
      key: "countyName",
      title: "İlçe",
      dataIndex: "countyName",
    },
    {
      key: "isRepeatOrder",
      title: "Sipariş Tekrarı mı?",
      dataIndex: "isRepeatOrder",
      render: (value) => value=== false ? 'Hayır' : 'Evet',
    },
    {
      key: "repeatOrderNumber",
      title: "Tekrarlı Sipariş Numarası",
      dataIndex: "repeatOrderNumber",
    },
    {
      key: "productId",
      title: "Ürün ID",
      dataIndex: "productId",
    },
    {
      key: "DependentProduct",
      title: "Bağlı Ürün",
      dataIndex: "DependentProduct",
      render: (value) => value=== false ? 'Yok' : 'Var',
    },
    // {
    //   title: "Detay",
    //   dataIndex: "",
    //   fixed: "right",
    //   key: "x",
    //   render: (_, Record) => (
    //     <NavLink to={`/Customer/${Record.id}`}>
    //       <Button type="primary">Detay</Button>
    //     </NavLink>
    //   ),
    // },
  ];

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setParams({ Size: pageSize });
    },
    onChange: (page) => {
      setParams({ Index: page });
    },
    pageSizeOptions: params.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };
  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };
  const onFilter = (values) => {
    const queryString = '?' + new URLSearchParams(values).toString();
    navigate(queryString);
    setParams({
      ...values,
      Index: 1,
      Size: 10
    });
  };

  const _demandedExport = () => {
    notify("info");
    periodicSalesReportExport(params)
      .then((res) => {
        downloadFile(res)
        notify("success");
      })
      .catch(() => {
        notify("error");
      });
  }; 
  return (
    <>
      <Collapse>
        <Panel header="Filtreler">
          <Filters initialValues={initialValues} onSubmit={onFilter} />
        </Panel>
      </Collapse>
      <Row>
        <Col style={{ padding: "24px 24px 24px 0", right: 0, marginLeft: "auto"}}>
        <Button type="primary" shape="round" icon={<DownloadOutlined />}
          onClick={_demandedExport}
        >
          Periyodik Sipariş Raporu Export
        </Button>
        </Col>
      </Row>
      <Table
        loading={status === "pending"}
        dataSource={status === "success" ? data.data : []}
        columns={Columns}
        pagination={pagination}
        rowKey={"serialNumber"}
      />
    </>
  );
};

export default PeriodicSalesReportTable;
