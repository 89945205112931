import { Form, Select, Input, DatePicker, Button, Col, Row } from "antd";
import React, { useContext } from "react";
import { OrderContext } from "../../Context/OrderContex";
import { formatDate } from "../../utils/date";
import {useLocation} from "react-router-dom";
import moment from "moment";

const { Option } = Select;

const PaymentStatuses = {
  data: [
    { id: 0, value: "Bekliyor" },
    { id: 1, value: "Ödendi" },
    { id: 2, value: "Hatalı" },
    { id: 3, value: "Parçalı İade" },
    { id: 4, value: "İade" },
    { id: 5, value: "Voided" },
    { id: 6, value: "Provision" }
  ]
};
function Filters({ onSubmit,initialValues }) {
  const { OrderItemStatuses } = useContext(OrderContext);

  const onFinish = (value) => {
    onSubmit({
      ...value,
      //OrderStatuses: getOrderStatus(value),
      //PaymentStatuses: getPaymentStatus(value),
      StartDate: value.StartDate ? formatDate(value.StartDate,false,"") : undefined,
      MinStartDate: value.MinStartDate ? formatDate(value.MinStartDate,false,"") : undefined,
      MaxStartDate: value.MaxStartDate ? formatDate(value.MaxStartDate,false,"") : undefined,
      MinEndDate: value.MinEndDate ? formatDate(value.MinEndDate,false,"") : undefined,
      MaxEndDate: value.MaxEndDate ? formatDate(value.MaxEndDate,false,"") : undefined,
      EndDate: value.EndDate ? formatDate(value.EndDate,false,"") : undefined
    });
  };

  /* const getOrderStatus = (value) => {
    if (value.OrderStatuses) {
      return value.OrderStatuses.find((OrderStatus) => OrderItemStatuses.data.find((Orderstat) => Orderstat.id === parseInt(OrderStatus)).id);
    }
  };

  const getPaymentStatus = (value) => {
    if (value.PaymentStatuses) {
      return value.PaymentStatuses.find((PaymentStatus) => PaymentStatuses.data.find((Paymentstat) => Paymentstat.id === parseInt(PaymentStatus)).id);
    }
  }; */


  return (
    <Form labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          onFinish={onFinish}
          initialValues={initialValues}
    >
      <div className="filters">
        <Row>
          <Col md={12}>
            <Form.Item label="İsim" name="BillingAddressFirstName">
              <Input />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Soyisim" name="BillingAddressLastName">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Item label="Email" name="BillingAddressEmail">
              <Input />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Sipariş no" name="OrderNumber">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
          <Form.Item label="Başl. Tarihi" name="StartDate">
            <DatePicker />
          </Form.Item>
          </Col>
          <Col md={12}>
          <Form.Item label="Bitiş Tarihi" name="EndDate">
            <DatePicker />
          </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
          <Form.Item label="Min Başl." name="MinStartDate">
            <DatePicker />
          </Form.Item>
          </Col>
           <Col md={12}>
          <Form.Item label="Max Başl." name="MaxStartDate">
            <DatePicker />
          </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
          <Form.Item label="Min Bitiş" name="MinEndDate">
            <DatePicker />
          </Form.Item>
          </Col>
          <Col md={12}>
          <Form.Item label="Max Bitiş" name="MaxEndDate">
            <DatePicker />
          </Form.Item>
          </Col>
        </Row>
                <Row>
          <Col md={12}>
        <Form.Item label="Sipariş dur:" name="OrderStatuses">
          <Select style={{ width: "100%" }} placeholder="Lütfen Seçin">
            {OrderItemStatuses.data.map((orderStatus) => (
              <Option key={orderStatus.id}>{orderStatus.value}</Option>
            ))}
          </Select>
        </Form.Item>
        </Col>
        <Col md={12}>
        <Form.Item label="Ödeme dur:" name="PaymentStatuses">
          <Select style={{ width: "100%" }} placeholder="Lütfen Seçin">
            {PaymentStatuses.data.map((PaymentStatus) => (
              <Option key={PaymentStatus.id}>{PaymentStatus.value}</Option>
            ))}
          </Select>
        </Form.Item>
        </Col>
        </Row>
      </div>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Filtrele
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Filters;
