import "antd/dist/antd.css";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import Layout from "./Container/Layout";
import { RequireAuth } from "./Container/AuthWrapper";
import { AuthContextProvider } from "./Context/AuthContext";
import { ToggleContextProvider } from "./Context/ToggleContext";

function App() {
  return (
    <AuthContextProvider>
      <ToggleContextProvider>
        <Router>
          <Routes>
            <Route path={"/Login"} element={<Login />} />
            <Route element={<RequireAuth />}>
              <Route path="*" element={<Layout />} />
            </Route>
          </Routes>
        </Router>
      </ToggleContextProvider>
    </AuthContextProvider>
  );
}

export default App;
