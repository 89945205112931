import { Form, Input, Button } from 'antd'
import React from 'react'

function Filters({ onSubmit,initialValues }) {
  const onFinish = (value) => {
    onSubmit({
      ...value,
    })
  }
  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      initialValues={initialValues}
      layout="horizontal"
      onFinish={onFinish}
      id="periodicSalesReportFilterForm"
    >
       <Form.Item label="Ürün Adı" name="ProductName">
        <Input />
      </Form.Item>
       <Form.Item label="Ürün ID" name="ProductId">
        <Input />
      </Form.Item>
      <Form.Item label="Seri Numarası" name="SerialNumber">
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Filtrele
        </Button>
      </Form.Item>
    </Form>
  )
}


export default Filters
