import { Descriptions } from 'antd'
import React from 'react';

function ShippingAddressCard(shippingProps) {
  const {
    fullName,
    email,
    phone,
    title,
    country,
    city,
    county,
    streetAddress,
    buildingNumber,
    buildingName,
    flatNumber,
    postalAddress,
    zipPostalCode
  } = shippingProps;
  const list = [
    {
      source: "fullName",
      title: 'İsim Soyisim',
      value: fullName,
    },
    {
      source: "email",
      title: 'E-mail',
      value: email
    },
    {
      source: "phone",
      title: 'Telefon numarası',
      value: phone,
    },
    {
      source: "title",
      title: 'Başlık',
      value: title,
    },
    {
      source: "city",
      title: 'Şehir',
      value: city
    },
    {
      source: "county",
      title: 'İlçe',
      value: county
    },
    {
      source: "streetAddress",
      title: 'Adres',
      value: streetAddress
    },
    {
      source: "buildingNumber",
      title: 'Bina No',
      value: buildingNumber
    },
    {
      source: "buildingName",
      title: 'Bina Adı',
      value: buildingName
    },
    {
      source: "flatNumber",
      title: 'Daire Numarası',
      value: flatNumber
    },
    {
      source: "postalAddress",
      title: 'Posta Adresi',
      value: postalAddress
    },
    {
      source: "zipPostalCode",
      title: 'Posta Kodu',
      value: zipPostalCode
    },
    {
      source: "country",
      title: 'Ülke',
      value: country,
    },
  ]
  return (
    <div className="order-detail-description-card">
    <Descriptions
      title="Teslimat Adresi"
      bordered
      column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 3, xs: 3 }}
    >
      {list.map(item => 
      <Descriptions.Item key={item.source} label={item.title}>{item.value}</Descriptions.Item>
      )}
    </Descriptions>
    </div>

  )
}

export default ShippingAddressCard
