import apiService from ".";

const consolePath = (path) => `/console${path}`;
const orderPath = (path) => `/Order${path}`;
const orderItemPath = (path) => `/OrderItem${path}`;
const orderNumberPath = (path) => `/order-number${path}`;
export const Orders = async (filters) => {
  return apiService.get(consolePath(orderPath("/filter")), {
    params: filters,
  });
};

export const OrderDetail = async (OrderNumber) => {
  return apiService.get(
    consolePath(orderPath(orderNumberPath(`/${OrderNumber}`)))
  );
};

export const updateProductSerialNumber = (orderNumber, itemSerialNumbers) => {
  return apiService.post(
    consolePath(orderItemPath(`/update-items-serial-number`)),
    {
      orderNumber,
      itemSerialNumbers,
    }
  );
};

export const sendShipment = (data) => {
  return apiService.post(
    consolePath(orderPath(`/send-shipment`)),
      data
  );
};

export const updateOrderStatus = (orderId, orderStatus) => {
  return apiService.post(consolePath(orderPath(`/update-order-status`)), {
    orderId,
    orderStatus,
  });
};

export const updatePaymentStatus = (orderId, paymentStatus) => {
  return apiService.post(consolePath(orderPath(`/update-payment-status`)), {
    orderId,
    paymentStatus,
  });
};

export const updateOrderItemStatus = (orderItemId, status) => {
  return apiService.post(consolePath(orderItemPath(`/update-order-item-status`)), {
    orderItemId,
    status,
  });
};

export const updateOrderStartOrEndDate = (orderItemId, startDate, endDate) => {
  return apiService.post(consolePath(orderItemPath(`/update-start-or-end-date`)), {
    id: orderItemId,
    startDate: startDate !== "" ? startDate : undefined,
    endDate: endDate !== "" ? endDate : undefined
  });
};

export const updateTrackingNumbers = (orderItemId, sendTrackingNumber, returnTrackingNumber) => {
  return apiService.post(consolePath(orderItemPath(`/update-tracking-numbers`)), {
    orderItemId,
    sendTrackingNumber,
    returnTrackingNumber
  });
};