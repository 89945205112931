import { Button, Collapse, Row, Col, Table } from "antd";
import { DownloadOutlined } from "@ant-design/icons/lib/icons";
import useRequest from "../../Hooks/useRequest";
import Filters from "./Filters";
import { NavLink } from "react-router-dom";
import {
  productClickReport,
  productClickReportExport,
} from "../../Service/report";
import { downloadFile } from "../../Service";
import usePagination from "../../Hooks/usePagination";
import { notify } from "../../utils/notify";

const { Panel } = Collapse;
const ProductClickReportTable = () => {
  const [Attributes, setAttributes] = usePagination({
    Id: undefined,
    Name: "",
    viewCount: undefined,
  });
  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(productClickReport, [Attributes]);
  const Columns = [
    {
      key: "id",
      title: "Id",
      dataIndex: "id",
      width: 70,
    },
    {
      key: "name",
      title: "Ürün Adı",
      dataIndex: "name",
      width: 100,
    },
    {
      key: "viewCount",
      title: "Görüntülenme Sayısı",
      dataIndex: "viewCount",
      width: 70,
    },
    // {
    //   title: "Detay",
    //   dataIndex: "",
    //   fixed: "right",
    //   key: "x",
    //   render: (_, Record) => (
    //     <NavLink to={`/Customer/${Record.id}`}>
    //       <Button type="primary">Detay</Button>
    //     </NavLink>
    //   ),
    // },
  ];

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setAttributes({ Size: pageSize });
    },
    onChange: (page) => {
      setAttributes({ Index: page });
    },
    pageSizeOptions: Attributes.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };
  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };
  const onFilter = (values) => {
    setAttributes({
      ...values,
    });
  };

  const _demandedExport = () => {
    notify("info");
    productClickReportExport()
      .then((res) => {
        downloadFile(res);
        notify("success");
      })
      .catch(() => {
        notify("error");
      });
  };

  return (
    <>
      {/* <Collapse>
        <Panel header="Filtreler">
          <Filters onSubmit={onFilter} />
        </Panel>
      </Collapse> */}
      <Row>
        <Col
          style={{ padding: "24px 24px 24px 0", right: 0, marginLeft: "auto" }}
        >
          <Button
            type="primary"
            shape="round"
            icon={<DownloadOutlined />}
            onClick={_demandedExport}
          >
            Ürün Tıklama Raporu Export
          </Button>
        </Col>
      </Row>
      <Table
        loading={status === "pending"}
        dataSource={status === "success" ? data.data : []}
        columns={Columns}
        pagination={pagination}
        rowKey={"id"}
      />
    </>
  );
};

export default ProductClickReportTable;
