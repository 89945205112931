import { createContext, useState } from 'react'
import { setAutHeader } from '../Service';
import { login as authLogin } from '../Service/console';
export const AuthContext = createContext({
  LoginStatu: 'empty',
  login: async (email, password) => Promise,
  logout: () => {}
})

export const AuthContextProvider = ({ children }) => {
  const user = JSON?.parse(localStorage?.getItem('user'))
  const [LoginStatu, setLoginStatu] = useState(user?.token?.accessToken ? 'success' : 'empty')

  const login = (email, password) => {
    setLoginStatu('pending')
    return authLogin({ email, password }).then(res => {
      localStorage.setItem('user', JSON.stringify(res));
      setAutHeader(res.token.accessToken)
      setLoginStatu('success')
      return Promise.resolve(res)
    }).catch(err => {
      setLoginStatu('error')
      return Promise.reject(err)
    })
  }
  const logout = () => {
    setAutHeader(false)
    setLoginStatu('empty')
    localStorage.removeItem('user');
  }
  return (
    <AuthContext.Provider value={{
      LoginStatu,
      login,
      logout
    }}>
      {children}
    </AuthContext.Provider>
  )
}