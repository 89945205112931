import React from 'react'
import { endLeaseReminder } from "../../Service/console";
import { Button, notification, Modal } from "antd";
import { notify } from "../../utils/notify";

const { confirm } = Modal;

function Home() {

    const _endLeaseReminder = () => {
    notify("info");
    endLeaseReminder()
      .then(() => {
        notify("success");
      })
      .catch(() => {
        notify("error");
      });
  }; 

  function Areyousure(action = () => { }) {
    confirm({
      title: 'Onaylıyor musunuz?',
      // icon: <ExclamationCircleOutlined />,
      okText: 'Onay',
      cancelText: 'İptal',
      onOk() {
        action();
      },
    });
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-between"}}>
      <h1>Kirala-admin'e hoşgeldiniz</h1>
      <Button
        type="secondary"
        onClick={() =>
              Areyousure(()=>_endLeaseReminder())}
      >
        Bitmek Üzere
      </Button>
    </div>
  )
}

export default Home
