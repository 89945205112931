import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons/lib/icons";
import { Descriptions, Select, Spin, Button, Modal, Form, Input } from "antd";
import React, { useState } from "react";
import {UpdateCustomerRate} from "../../Service/customer";
import { formatDate } from "../../utils/date"; 

const { Option } = Select;
function CustomerCard(CustomerProps) {
  const { id, firstName, lastName, createdAt, email, customerInfo, isVerified, phone, isActive, isDeleted } =
    CustomerProps;
  const [updating, setUpdating] = useState(false);
  const [isRateModalOpen, setRateModalOpen] = useState(false);
  const list = [
    {
      source: "id",
      title: "Müşteri id",
      display: id,
    },
    {
      source: "firstName",
      title: "Müşteri Adı",
      display: firstName
    },
    {
      source: "lastName",
      title: "Müşteri Soyadı",
      display: lastName,
    },
    {
      source: "createdAt",
      title: "Oluşturulma Tarihi",
      display: formatDate(createdAt,true),
    },
    {
      source: "email",
      title: "E-mail",
      display: email,
    },
    {
      source: "identityNumber",
      title: "TC No",
      display: customerInfo?.identificationNumber,
    },
    {
      source: "dateOfBirth",
      title: "Doğum Tarihi",
      display: formatDate(customerInfo?.dateOfBirth),
    },
    {
      source: "phone",
      title: "Telefon Numarası",
      display: phone,
    },
    {
      source: "lastLoginDateAt",
      title: "Son Giriş",
      display: formatDate(customerInfo?.lastLoginDateAt,true),
    },
    {
      source: "isVerified",
      title: "Doğrulanma",
      display: isVerified ? "Doğrulanmış" : "Doğrulanmamış",
    },
    {
      source: "isActive",
      title: "Durum",
      display: isActive ? "Aktif" : "Pasif",
    },
    {
      source: "isDeleted",
      title: "Silinme",
      display: isDeleted ? "Silindi" : "Silinmedi",
    },
    {
      source: "rate",
      title: "Rate",
      display: customerInfo?.rate ?? "-",
    },
  ];

  const _updateCustomerRate = async (id, rate) => {
    setUpdating(true);
    await UpdateCustomerRate(id, rate).then(() => {
    setUpdating(false)
    // updateCustomer()
    setRateModalOpen(false);
    });
  }

  const onUpdateRateSubmit = (values) => {
    setUpdating(true);
    _updateCustomerRate(id, parseInt(values.rate))
      .then(() => {
        setUpdating(false);
        setRateModalOpen(false);
      })
      .catch(() => {
        setUpdating(false);
      });
  };
  return (
    <Spin spinning={updating}>
    <Modal title="Rate Güncelle" visible={isRateModalOpen} onCancel={() => setRateModalOpen(false)} footer={null}>
    <Form id="update-rate" onFinish={onUpdateRateSubmit} >
    <Form.Item
       label="Rate"
        name="rate"
        rules={[{ required: true, message: 'Lütfen doldurunuz.' }]}>
    <Input type="number" />
    </Form.Item>
      <Button
        htmlType="submit"
        style={{ marginBottom: 20 }}
      >
        Güncelle
      </Button>
      </Form>
    </Modal>
      <Button
        onClick={() => {
          setRateModalOpen(true);
        }}
        style={{ marginBottom: 20 }}
      >
        Rate Güncelle
      </Button>
      <Descriptions title="Müşteri Detay" bordered column={2}>
        {list.map((item) => (
          <Descriptions.Item key={item.source} label={item.title}>
            <EditableItem
              value={item.value}
              display={item.display}
              options={item.options}
              editable={item.editable}
              submit={item.submit}
            />
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Spin>
  );
}

const EditableItem = ({ value, submit, display, options = [], editable }) => {
  const [edit, setedit] = useState(false);
  const [selected, setselected] = useState(value);
  return !edit ? (
    <>
      <p>{display}</p>
      {editable && (
        <EditOutlined
          onClick={() => {
            setedit(true);
          }}
        />
      )}
    </>
  ) : (
    <>
      <div>
        <Select
          defaultValue="lucy"
          style={{ width: 120 }}
          onChange={(val) => setselected(val)}
          value={selected}
        >
          {options.map((option) => (
            <Option value={option.id}>{option.value}</Option>
          ))}
        </Select>
      </div>
      <CheckOutlined onClick={() => submit(selected)} />
      <CloseOutlined onClick={() => setedit(false)} />
    </>
  );
};

export default CustomerCard;
