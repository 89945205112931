import {Button, Space, Input, Form, Modal, Select, InputNumber} from "antd";
import React from "react";
const { Option } = Select;

function CargoModal({ visible, onFinish, onCancel, loading,data }) {

    const ProviderTypes = {
        data: [
            { id: 1, value: "BoltCargo",label: "Bolt Kargo" },
            { id: 2, value: "YurticiCargo",label: "Yurtiçi Kargo" },
            { id: 3, value: "HepsiJetCargo",label: "Hepsi Jet Kargo" },
        ]
    };

    const CargoDirectionTypes = {
        data: [
            { id: 1, value: "Outgoing",label: "Giden Kargo" },
            { id: 2, value: "Incoming",label: "Gelen Kargo" },
        ]
    };

    return (
        <Modal
            title="Kargo Modal"
            visible={visible}
            footer={null}
            onCancel={onCancel}
            destroyOnClose
            width={750}
        >
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                layout="horizontal"
                onFinish={onFinish}
            >
                <Form.Item
                    label="Sipariş No: "
                    name="OrderNumber"
                    initialValue={data?.orderNumber}
                    rules={[{ required: true, message: "Sipariş no zorunludur" }]}
                >
                    <Input placeholder="OrderNumber" />
                </Form.Item>

                <Form.Item label="Kargo Firması: " name="ProviderType" rules={[{ required: true, message: "Kargo firması zorunludur" }]}>
                    <Select style={{ width: "100%" }} placeholder="Lütfen seçim yapın">
                        {ProviderTypes.data.map((item) => (
                            <Option key={item.id} value={item.value}>{item.label}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Kargo Tipi: " name="CargoDirectionType" rules={[{ required: true, message: "Kargo tipi zorunludur" }]}>
                    <Select style={{ width: "100%" }} placeholder="Lütfen seçim yapın">
                        {CargoDirectionTypes.data.map((item) => (
                            <Option key={item.id} value={item.value}>{item.label}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Paket Miktarı: "
                    name="Quantity"
                    rules={[{ required: true, message: "Paket Miktarı zorunludur" }]}
                >
                    <InputNumber min={1} max={10} placeholder="Quantity" />
                </Form.Item>

                <Form.Item label="Ürünler: " name="OrderItemId" rules={[{ required: true, message: "Ürünler zorunludur" }]}>
                    <Select style={{ width: "100%" }}  mode="multiple" allowClear placeholder="Lütfen seçim yapın">
                        {data?.items?.map((item) => (
                            <Option key={item.productId} value={item.id}>{item.productName}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Space split>
                        <Button onClick={onCancel} loading={loading}>
                            İptal
                        </Button>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Kaydet
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CargoModal;
