import {Collapse, Table, Modal, Dropdown, Menu, Tag, Button} from "antd";
import useRequest from "../../Hooks/useRequest";
import {Orders, sendShipment} from "../../Service/order";
import Filters from "./Filters";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import usePagination from "../../Hooks/usePagination";
import {
  endLeaseReminder,
  startInsurance,
  verifiedCustomer,
  unVerifiedCustomer, getManualReturnCode,
} from "../../Service/console";
import React, {useContext, useEffect, useState} from "react";
import { OrderContext } from "../../Context/OrderContex";
import { formatDate } from "../../utils/date";
import { formatPrice } from "../../utils/price";
import { notify } from "../../utils/notify";
import CargoModal from "../../Modals/CargoModal";
import ReturnedShipmentModal from "../../Modals/ReturnedShipmentModal";
import moment from "moment";

const { confirm } = Modal;
const { Panel } = Collapse;
const OrdersTable = () => {

  const { search } = useLocation();
  const query = new URLSearchParams(search)

  const [cargoModalVisible, setCargoModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [returnedCargoCode, setReturnedCargoCode] = useState('');
  const [initialValues, setInitialValues] = useState({});
  const [params, setParams] = usePagination({
    OrderStatuses: [],
    PaymentStatuses: [],
    OrderNumber: "",
    BillingAddressFirstName: "",
    BillingAddressLastName: "",
    BillingAddressEmail: "",
    StartDate: "",
    MinStartDate: "",
    MaxStartDate: "",
    EndDate: "",
    MinEndDate: "",
    MaxEndDate: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (currentOrderPage > 0) {
      setTimeout(() => {
        setParams({ Index: currentOrderPage });
      }, 500);
    }
  }, []);

  useEffect(() => {
    const data = {};
    for (const [key, value] of Object.entries(params)) {
      if(String(query?.get(key)) !== "undefined" && String(query?.get(key)) !== "null"){
        data[key] = query?.get(key)
      }
    }

    setInitialValues({
      ...data,
      StartDate: data.StartDate ? moment(data.StartDate,"YYYY-MM-DD") : undefined,
      MinStartDate: data.MinStartDate ? moment(data.MinStartDate,"YYYY-MM-DD") : undefined,
      MaxStartDate: data.MaxStartDate ? moment(data.MaxStartDate,"YYYY-MM-DD") : undefined,
      MinEndDate: data.MinEndDate ? moment(data.MinEndDate,"YYYY-MM-DD") : undefined,
      MaxEndDate: data.MaxEndDate ? moment(data.MaxEndDate,"YYYY-MM-DD") : undefined,
      EndDate: data.EndDate ? moment(data.EndDate,"YYYY-MM-DD") : undefined
    })
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setParams({
        ...params,
        ...initialValues,
        StartDate: initialValues.StartDate ? formatDate(initialValues.StartDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
        MinStartDate: initialValues.MinStartDate ? formatDate(initialValues.MinStartDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
        MaxStartDate: initialValues.MaxStartDate ? formatDate(initialValues.MaxStartDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
        MinEndDate: initialValues.MinEndDate ? formatDate(initialValues.MinEndDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
        MaxEndDate: initialValues.MaxEndDate ? formatDate(initialValues.MaxEndDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
        EndDate: initialValues.EndDate ? formatDate(initialValues.EndDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined
      })
    },400)
  }, [initialValues]);

  const { OrderStatus, PaymentStatus, currentOrderPage, setCurrentOrderPage } =
    useContext(OrderContext);

  const { data = {}, status, message, update } = useRequest(Orders, [params]);
  const Columns = [
    {
      key: "id",
      title: "id",
      dataIndex: "id",
      width: 100,
    },
    {
      key: "id",
      title: "Sipariş Türü",
      dataIndex: "id",
      width: 100,
      render: (_, data) => (data.isPurchasedOrder ? <Tag color="green">Satış</Tag> : <Tag color="orange">Kiralama</Tag>),
    },
    {
      key: "customerId",
      title: "Müşteri id",
      dataIndex: "customerId",
      render: (_, Customer) => (
        <NavLink to={`/Customer/${Customer.customerId}`}>
          <a style={{ color: "black", textDecoration: "underline" }}>
            {Customer.customerId}
          </a>
        </NavLink>
      ),
      width: 100,
    },
    {
      key: "firstName",
      title: "İsim",
      dataIndex: "firstName",
      width: 100,
    },
    {
      key: "lastName",
      title: "Soyisim",
      dataIndex: "lastName",
      width: 100,
    },
    {
      key: "orderNumber",
      title: "Sipariş Numarası",
      dataIndex: "orderNumber",
      width: 100,
    },
    {
      key: "orderStatus",
      title: "Sipariş Durumu",
      dataIndex: "orderStatus",
      render: (value) =>
        OrderStatus.data.find((Orderstat) => Orderstat.id === value)?.value,
      width: 150,
    },
    {
      key: "totalOrderCount",
      title: "Toplam Sipariş",
      dataIndex: "totalOrderCount",
      width: 100,
    },
    {
      key: "paymentStatus",
      title: "Ödeme Durumu",
      dataIndex: "paymentStatus",
      render: (value) =>
        PaymentStatus.data.find((paymentStat) => paymentStat.id === value)
          ?.value,
      width: 100,
    },
    {
      key: "total",
      title: "Tutar",
      dataIndex: "total",
      width: 100,
      render: (price) => formatPrice(price),
    },
    {
      key: "createdAt",
      title: "Oluşturma Tarihi",
      dataIndex: "createdAt",
      render: (date) => formatDate(date),
    },
    // {
    //   key: "StartDate",
    //   title: "Başlangıç Tarihi",
    //   dataIndex: "StartDate",
    //   render: (startDate) => formatDate(startDate),
    // },
    // {
    //   key: "EndDate",
    //   title: "Bitiş Tarihi",
    //   dataIndex: "EndDate",
    //   render: (EndDate) => formatDate(EndDate),
    // },

    {
      title: "",
      fixed: "right",
      dataIndex: "",
      key: "y",
      render: (_, Record) => (
        <Dropdown
          overlay={() => actionMenu(Record)}
          trigger={["click"]}
          placement="bottomLeft"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 23 23"
            style={{
              transform: "rotate(90deg)",
              filter:
                "invert(54%) sepia(1%) saturate(4393%) hue-rotate(176deg) brightness(101%) contrast(86%)",
              cursor: "pointer",
            }}
          >
            <g fill="none" fillRule="evenodd">
              <path d="M0 0H23V23H0z" />
              <path
                fill="#666666"
                fillRule="nonzero"
                d="M6 10c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm6 0c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm6 0c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z"
              />
            </g>
          </svg>
        </Dropdown>
      ),
    },
  ];

  const actionMenu = (Record) => {
    return (
      <Menu>
        <Menu.Item key="1">
          <div
            onClick={() =>
              Areyousure(() =>
                _verifiedCustomer(Record.email, Record.orderNumber)
              )
            }
          >
            <span>Findeks Onay</span>
          </div>
        </Menu.Item>
        <Menu.Item key="2">
          <div
            onClick={() =>
              Areyousure(() =>
                _unVerifiedCustomer(Record.email, Record.orderNumber)
              )
            }
          >
            <span>Findeks Red</span>
          </div>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to={`/Order/${Record.orderNumber}?isPurchasedOrder=${Record.isPurchasedOrder}`}>Detay</NavLink>
        </Menu.Item>
      </Menu>
    );
  };

  function Areyousure(action = () => {}) {
    confirm({
      title: "Onaylıyor musunuz?",
      // icon: <ExclamationCircleOutlined />,
      okText: "Onay",
      cancelText: "İptal",
      onOk() {
        action();
      },
    });
  }

  const _verifiedCustomer = (email, orderNumber) => {
    notify("info");
    verifiedCustomer(email, orderNumber)
      .then(() => {
        notify("success");
        update();
      })
      .catch(() => {
        notify("error");
      });
  };

  const _unVerifiedCustomer = (email, orderNumber) => {
    notify("info");
    unVerifiedCustomer(email, orderNumber)
      .then(() => {
        notify("success");
        update();
      })
      .catch(() => {
        notify("error");
      });
  };

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setParams({ Size: pageSize });
    },
    onChange: (page) => {
      setParams({ Index: page });
      setCurrentOrderPage(page);
    },
    pageSizeOptions: params.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };

  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };

  const onFilter = (values) => {
    const queryString = '?' + new URLSearchParams(values).toString();
    navigate(queryString);
    setParams({
      ...values,
      Index: 1,
      Size: 10
    });
  };

  const onFinish = (values) => {
    //console.log(values)
    notify("info");
    getManualReturnCode(values)
        .then((res) => {
          //console.log(res)
          setReturnedCargoCode(res)
          notify("success");
          //setCargoModalVisible(false)
        })
        .catch((err) => {
          setReturnedCargoCode("")
          notify("error");
          //setCargoModalVisible(false)
        });
  };

  useEffect(() => {
    setReturnedCargoCode("")
  },[cargoModalVisible])

  const onCancel = () => {
    setCargoModalVisible(false)
  };

  return (
    <>

      <ReturnedShipmentModal code={returnedCargoCode} visible={cargoModalVisible} onFinish={onFinish} onCancel={onCancel} loading={loading} />
      <div className="collapseWrapper">
        <Button className="cargoBtn" onClick={setCargoModalVisible} type="secondary" htmlType="button">
          İade Kargo Kodu Oluştur
        </Button>
        <Collapse>
          <Panel header="Filtreler">
            <Filters initialValues={initialValues} onSubmit={onFilter} />
          </Panel>
        </Collapse>
      </div>
      <div className="orders__table">
        <Table
          loading={status === "pending"}
          dataSource={status === "success" ? data.data : []}
          columns={Columns}
          pagination={pagination}
          rowKey={"id"}
        />
      </div>
    </>
  );
};

export default OrdersTable;
