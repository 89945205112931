import { Button, Collapse, Row, Col, Table, Tag } from "antd";
import { DownloadOutlined } from "@ant-design/icons/lib/icons";
import useRequest from "../../Hooks/useRequest";
import Filters from "./Filters";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {
  demandedProductReport,
  demandedProductReportExport
} from "../../Service/report";
import { downloadFile } from "../../Service";
import usePagination from "../../Hooks/usePagination";
import { notify } from "../../utils/notify";
import { formatDate } from "../../utils/date";
import {useEffect, useState} from "react";
import moment from "moment";

const { Panel } = Collapse;
const DemandedProductReportTable = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search)
  const [initialValues, setInitialValues] = useState({});
  const navigate = useNavigate();
  const [Attributes, setAttributes] = usePagination({
    ProductId: undefined,
    CustomerId: undefined,
    IsGuest: false,
    ProductName: "",
    Quantity: undefined,
    UnitPrice: undefined,
  });


  useEffect(() => {
    const data = {};
    for (const [key, value] of Object.entries(Attributes)) {
      if(String(query?.get(key)) !== "undefined" && String(query?.get(key)) !== "null"){
        data[key] = query?.get(key)
      }
    }

    setInitialValues({
      ...data,
      StartDate: data.StartDate ? moment(data.StartDate,"YYYY-MM-DD") : undefined,
      EndDate: data.EndDate ? moment(data.EndDate,"YYYY-MM-DD") : undefined
    })
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setAttributes({
        ...Attributes,
        ...initialValues,
        StartDate: initialValues.StartDate ? formatDate(initialValues.StartDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
        EndDate: initialValues.EndDate ? formatDate(initialValues.EndDate,false,"YYYY-MM-DDTHH:mm:ss") : undefined,
      })
    },400)
  }, [initialValues]);

  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(demandedProductReport, [Attributes]);
  const Columns = [
    {
      key: "productId",
      title: "Ürün Id",
      dataIndex: "productId",
      width: 70,
    },
    {
      key: "customerId",
      title: "Müşteri Id",
      dataIndex: "customerId",
      width: 70,
    },
    {
      key: "isGuest",
      title: "Misafir",
      dataIndex: "isGuest",
      render: (isGuest) => (
        <Tag color={isGuest ? "geekblue" : "volcano"}>
          {isGuest ? "Misafir" : "Müşteri"}
        </Tag>
      ),
      width: 70,
    },
    {
      key: "productName",
      title: "Ürün Adı",
      dataIndex: "productName",
      width: 200,
    },
    {
      key: "quantity",
      title: "Adet",
      dataIndex: "quantity",
      width: 70,
    },
    {
      key: "priceTypeText",
      title: "Kiralama Süresi",
      dataIndex: "priceTypeText",
      render: (priceTypeText) => (
        <span>
          {priceTypeText}
        </span>
      ),
      width: 70,
    },
    {
      key: "createdAt",
      title: "Oluşturulma Tarihi",
      dataIndex: "createdAt",
      render: (date) => formatDate(date,true),
    },
    // {
    //   title: "Detay",
    //   dataIndex: "",
    //   fixed: "right",
    //   key: "x",
    //   render: (_, Record) => (
    //     <NavLink to={`/Customer/${Record.id}`}>
    //       <Button type="primary">Detay</Button>
    //     </NavLink>
    //   ),
    // },
  ];

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setAttributes({ Size: pageSize });
    },
    onChange: (page) => {
      setAttributes({ Index: page });
    },
    pageSizeOptions: Attributes.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };
  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };
  const onFilter = (values) => {
    const queryString = '?' + new URLSearchParams(values).toString();
    navigate(queryString);
    setAttributes({
      ...values,
      Index: 1,
      Size: 10
    });
  };

  const _demandedExport = () => {
    notify("info");
    demandedProductReportExport(Attributes)
      .then((res) => {
        downloadFile(res)
        notify("success");
      })
      .catch((err) => {
        console.log(err);
        notify("error");
      });
  }; 

  return (
    <>
      <Collapse>
        <Panel header="Filtreler">
          <Filters initialValues={initialValues} onSubmit={onFilter} />
        </Panel>
      </Collapse>
      <Row>
        <Col style={{ padding: "24px 24px 24px 0", right: 0, marginLeft: "auto"}}>
          <Button type="primary" shape="round" icon={<DownloadOutlined />}
            onClick={_demandedExport}
          >
            Sepet ve Sipariş Raporu Export
          </Button>
        </Col>
      </Row>
      <Table
        loading={status === "pending"}
        dataSource={status === "success" ? data.data : []}
        columns={Columns}
        pagination={pagination}
        rowKey={"id"}
      />
    </>
  );
};

export default DemandedProductReportTable;
