import { Form, Input, DatePicker, Button, Row, Col } from 'antd'
import React from 'react'
import { formatDate } from "../../utils/date"; 

function Filters({ onSubmit,initialValues }) {
  const onFinish = (value) => {
    onSubmit({
      ...value,
      MinStartDate: value.MinStartDate ? formatDate(value.MinStartDate,false,"") : undefined,
      MaxStartDate: value.MaxStartDate ? formatDate(value.MaxStartDate,false,"") : undefined,
      MinEndDate: value.MinEndDate ? formatDate(value.MinEndDate,false,"") : undefined,
      MaxEndDate: value.MaxEndDate ? formatDate(value.MaxEndDate,false,"") : undefined,
      OrderStartCreatedDate: value.OrderStartCreatedDate ? formatDate(value.OrderStartCreatedDate,false,"") : undefined,
      OrderEndCreatedDate: value.OrderEndCreatedDate ? formatDate(value.OrderEndCreatedDate,false,"") : undefined,
    })
  }
  return (
    <Form
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      initialValues={initialValues}
      layout="horizontal"
      className="filters"
      onFinish={onFinish}
    >
      <Row>
        <Col md={12}>
          <Form.Item label="Ürün Adı" name="ProductName">
            <Input />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item label="Seri Numarası" name="ProductSerialNumber">
            <Input />
          </Form.Item>
        </Col>
      </Row>
        <Row>
          <Col md={8}>
          <Form.Item label="Min. Kiralama Başlangıç" name="MinStartDate">
            <DatePicker />
          </Form.Item>
          </Col>
           <Col md={8}>
          <Form.Item label="Max. Kiralama Başlangıç" name="MaxStartDate">
            <DatePicker />
          </Form.Item>
          </Col>
           <Col md={8}>
             <Form.Item label="Sipariş Başlangıç tarihi" name="OrderStartCreatedDate">
               <DatePicker />
             </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
          <Form.Item label="Min. Kiralama Bitiş" name="MinEndDate">
            <DatePicker />
          </Form.Item>
          </Col>
          <Col md={8}>
          <Form.Item label="Max. Kiralama Bitiş" name="MaxEndDate">
            <DatePicker />
          </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item label="Sipariş Bitiş tarihi" name="OrderEndCreatedDate">
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Filtrele
        </Button>
      </Form.Item>
    </Form>
  )
}


export default Filters
