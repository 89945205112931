import { Form, Upload, Button, Row, Col, Descriptions, notification, Space  } from "antd";
import React from "react";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons/lib/icons";
import {
  sampleImportForImportProducts,
  sampleImportForUpdatePrices,
  productImport,
  ImportForUpdatePrices,
  getActiveBrands
} from "../../Service/console";
import { downloadFile } from "../../Service";
import { notify } from "../../utils/notify";

function Filters() {

  const close = () => {
    console.log(
        'Vazgeçildi',
    );
  };

  const openNotification = ({ file, onSuccess },func) => {
    const key = `open${Date.now()}`;
    const btn = (
        <Space>
          <Button type="link" size="small" onClick={() => notification.destroy()}>
            Vazgeç
          </Button>
          <Button type="primary" size="small" onClick={() => {
            func({ file, onSuccess })
            notification.destroy()
          }}>
            Yükle
          </Button>
        </Space>
    );


    notification.info({
      message: 'Emin misiniz?',
      description:
          'Yükleme yapmak istediğinizden emin misiniz?',
      btn,
      key,
      onClose: close,
      placement : "topLeft"
    });
  };


  const uploadOptions = {
    name: "file",
    showUploadList: false,
    accept: ".xls,.xlsx",
  };

  const sendProductImport = ({ file, onSuccess }) => {

    const fmData = new FormData();
    fmData.append("file", file);

    notify("info");
    productImport(fmData)
        .then((res) => {
          notify("success");
        })
        .catch(() => {
          notify("error");
        });
  }



  const sendImportForUpdatePrices = ({ file, onSuccess }) => {

    const fmData = new FormData();
    fmData.append("file", file);

    notify("info");
    ImportForUpdatePrices(fmData)
        .then((res) => {
          notify("success");
        })
        .catch(() => {
          notify("error");
        });
  }

  const _demandedExport = (exportType) => {
    notify("info");
    exportType()
        .then((res) => {
          downloadFile(res);
          notify("success");
        })
        .catch(() => {
          notify("error");
        });
  };

  return (
      <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          id="periodicSalesReportFilterForm"
      >
        <Row align="center">
          <Col lg={6} md={12}>
            <Descriptions title="Genel Ürün Güncelleme" />
            <Form.Item>
              <Upload {...uploadOptions} customRequest={({ file, onSuccess }) => openNotification({ file, onSuccess },sendProductImport)}>
                <Button
                    type="primary"
                    style={{
                      background: "#fd7e14",
                      borderColor: "#fd7e14",
                      color: "#ffffff",
                    }}
                    shape="round"
                    icon={<UploadOutlined />}
                >
                  Excel Ekle
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Button
                  type="primary"
                  style={{
                    background: "#fd7e14",
                    borderColor: "#fd7e14",
                    color: "#ffffff",
                  }}
                  shape="round"
                  onClick={() => _demandedExport(sampleImportForImportProducts)}
                  icon={<DownloadOutlined />}
              >
                Örnek Çıktıyı İndir
              </Button>
            </Form.Item>
          </Col>
          <Col lg={6} md={12}>
            <Descriptions title="Ürün Fiyat Güncelleme" />
            <Form.Item>
              <Upload {...uploadOptions}  customRequest={({ file, onSuccess }) => openNotification({ file, onSuccess },sendImportForUpdatePrices)}>
                <Button
                    type="primary"
                    style={{
                      background: "#0d6efd",
                      borderColor: "#0d6efd",
                      color: "#ffffff",
                    }}
                    shape="round"
                    icon={<UploadOutlined />}
                >
                  Excel Ekle
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Button
                  type="primary"
                  style={{
                    background: "#0d6efd",
                    borderColor: "#0d6efd",
                    color: "#ffffff",
                  }}
                  shape="round"
                  onClick={() => _demandedExport(sampleImportForUpdatePrices)}
                  icon={<DownloadOutlined />}
              >
                Örnek Çıktıyı İndir
              </Button>
            </Form.Item>
          </Col>
          <Col lg={6} md={12}>
            <Descriptions title="Geçerli Marka İsimleri" />
            <Form.Item>
              <Button
                  type="primary"
                  style={{
                    background: "#0d6efd",
                    borderColor: "#0d6efd",
                    color: "#ffffff",
                  }}
                  shape="round"
                  onClick={() => _demandedExport(getActiveBrands)}
                  icon={<DownloadOutlined />}
              >
                Excel Çıktısını İndir
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
  );
}

export default Filters;
