import apiService from ".";
import axios from "axios";

const consolePath = (path) => `/console${path}`;
const customerPath = (path) => `/Customer${path}`;
const orderPath = (path) => `/Order${path}`;
const orderItemPath = (path) => `/OrderItem${path}`;
const productPath = (path) => `/Product${path}`;
const purchasePricePath = (path) => `/PurchasePrice${path}`;
const shipmentPath = (path) => `/Shipment${path}`;

export const login = async (credential) => {
    return apiService.post(consolePath("/Auth/login"), credential);
};

export const customerWithdDetails = async (filters) => {
    return apiService.post(
        consolePath(customerPath("/customer-with-details")),
        {},
        {
            params: filters,
        }
    );
};

export const startInsurance = async (OrderNumber) => {
    return apiService.post(
        consolePath(orderPath("/start-insurance-send")),
        {},
        {
            params: {
                OrderNumber,
                ReturnBaseUrl: "https://live.d3lnj57nlw4aof.amplifyapp.com/assurance",
            },
        }
    );
};

export const endLeaseReminder = async (OrderNumber) => {
    return apiService.post(consolePath(orderPath("/end-lease-reminder")));
};

export const getProductList = async (filters) => {
    return apiService.get(consolePath(productPath("/get-product-list")), {
        params: filters,
    });
};

export const getActiveBrands = async (filters) => {
    return apiService.get(consolePath(productPath("/get-active-brands")), {
        params: filters,
        responseType: "blob",
    });
};

export const getBarcode = async (request) => {
    return apiService.get(consolePath("/Barcode/get-barcode"), {
        params: {
            request : request
        },
    });
};

export const getPurchasedPrice = async (params) => {
    return apiService.get(consolePath(purchasePricePath(`/get-purchase-price`)),{
        params,
    });
};

export const getProductDetail = async (productId) => {
    return apiService.get(consolePath(productPath(`/detail/${productId}`)));
};

export const sampleImportForImportProducts = async (filters) => {
    return apiService.get(
        consolePath(productPath("/sample-import-for-import-products")),
        {
            params: filters,
            responseType: "blob",
        }
    );
};

export const sampleImportForUpdatePrices = async (filters) => {
    return apiService.get(
        consolePath(productPath("/sample-import-for-update-prices")),
        {
            params: filters,
            responseType: "blob",
        }
    );
};
export const sendTrustpilotReviewMail = async (email) => {
    return apiService.get(
        consolePath(orderItemPath(`/send-trust-pilot-review-mail/${email}`))
    );
};
export const getRentalOrders = async (filters) => {
    return apiService.post(
        consolePath(orderItemPath("/get-rental-orders")),
        {},
        {
            params: filters,
        }
    );
};
export const updateItemsPurchased = async (filters) => {
    return apiService.post(consolePath(orderItemPath("/update-items-purchased")), {
        orderItemId: filters.orderItemId,
        isOrderItemPurchased: filters.isOrderItemPurchased
    });

};

export const productImport = async (formdata) => {
    return await apiService
        .post(
            consolePath(productPath("/import")),
            formdata,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
        .then((resp) => resp);
};

export const ImportForUpdatePrices = async (formdata) => {
    return await apiService
        .post(
            consolePath(productPath("/import-for-update-prices")),
            formdata,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
        .then((resp) => resp);
};

export const verifiedCustomer = async (email, orderNumber) => {
    return apiService.post(
        consolePath(orderPath("/confirm-order")),
        {},
        { params: { email, orderNumber } }
    );
};

export const unVerifiedCustomer = async (email, orderNumber) => {
    return apiService.post(
        consolePath(orderPath("/unConfirm-order")),
        {},
        { params: { email, orderNumber } }
    );
};

export const OrderItemStatus = (damagedObject) => {
    return apiService.post(
        consolePath(orderPath("/order-item-damage-status")),
        {},
        {
            params: damagedObject,
        }
    );
};

export const customerDataExport = async () => {
    return await apiService.get(consolePath("/Customer/export"), {
        responseType: "blob",
    });
};

export const exportProduct = async () => {
    return await apiService.get(
        consolePath(productPath("/export-product-click")),
        {
            responseType: "blob",
        }
    );
};

export const demandedProduct = async () => {
    return await apiService.get(consolePath("/DemandedProduct/export"), {
        responseType: "blob",
    });
};

export const InvoiceAndWaybillExport = async (params) => {
    return apiService.post(
        consolePath(orderItemPath("/dispatch-export")),
        {},
        {
            responseType: "blob",
            params: params,
        }
    );
};

export const getManualReturnCode = async (params) => {
    return apiService.post(
        consolePath(shipmentPath("/send-manual-return-code")),
        params
    );
};

export const getOrderShipment = async (filters) => {
    return apiService.get(consolePath(shipmentPath("/get-order-shipment")), {
        params: {
            ...filters,
            CargoDirectionType : "Outgoing"
        },
    });
};

export const getOrderReturned = async (filters) => {
    return apiService.get(consolePath(shipmentPath("/get-order-shipment")), {
        params: {
            ...filters,
            CargoDirectionType : "Incoming"
        },
    });
};
