import { Button, Collapse, Table, Row, Col } from "antd";
import { DownloadOutlined } from "@ant-design/icons/lib/icons";
import useRequest from "../../Hooks/useRequest";
import Filters from "./Filters";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {
  productSalesReport,
  productSalesReportExport,
} from "../../Service/report";
import { downloadFile } from "../../Service";
import usePagination from "../../Hooks/usePagination";
import { notify } from "../../utils/notify";
import { formatDate } from "../../utils/date";
import {useEffect, useState} from "react";
import moment from "moment";

const { Panel } = Collapse;
const ProductSalesReportTable = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search)
  const [initialValues, setInitialValues] = useState({});
  const navigate = useNavigate();
  const [params, setParams] = usePagination({
    ProductName: "",
    ProductSerialNumber: "",
    MinStartDate: "",
    MaxStartDate: "",
    OrderStartCreatedDate: "",
    MinEndDate: "",
    MaxEndDate: "",
    OrderEndCreatedDate: "",
  });
  useEffect(() => {
    const data = {};
    for (const [key, value] of Object.entries(params)) {
      if(String(query?.get(key)) !== "undefined" && String(query?.get(key)) !== "null"){
        data[key] = query?.get(key)
      }
    }

    setInitialValues({
      ...data,
      MinStartDate: data.MinStartDate ? moment(data.MinStartDate,"YYYY-MM-DD") : undefined,
      MaxStartDate: data.MaxStartDate ? moment(data.MaxStartDate,"YYYY-MM-DD") : undefined,
      MinEndDate: data.MinEndDate ? moment(data.MinEndDate,"YYYY-MM-DD") : undefined,
      MaxEndDate: data.MaxEndDate ? moment(data.MaxEndDate,"YYYY-MM-DD") : undefined,
      OrderStartCreatedDate: data.OrderStartCreatedDate ? moment(data.OrderStartCreatedDate,"YYYY-MM-DD") : undefined,
      OrderEndCreatedDate: data.OrderEndCreatedDate ? moment(data.OrderEndCreatedDate,"YYYY-MM-DD") : undefined,
    })
  }, []);

  useEffect(() => {
    setParams({
      ...params,
      ...initialValues,
    })
  }, [initialValues]);

  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(productSalesReport, [params]);

  const Columns = [
    {
      key: "productName",
      title: "Ürün Adı",
      dataIndex: "productName",
      width: 200,
    },
    {
      key: "productSerialNumber",
      title: "Seri Numarası",
      dataIndex: "productSerialNumber",
      width: 100,
    },
    {
      key: "stockAddedDate",
      title: "Stok Eklenme Tarihi",
      dataIndex: "stockAddedDate",
      render: (date) => formatDate(date),
      width: 100,
    },
    {
      key: "totalOrderItem",
      title: "Toplam Kiralama Sayısı",
      dataIndex: "totalOrderItem",
      width: 100,
    },
    {
      key: "totalOrderItemByWeekly",
      title: "Toplam Haftalık Kiralama Sayısı",
      dataIndex: "totalOrderItemByWeekly",
      width: 100,
    },
    {
      key: "totalOrderItemByMonthly",
      title: "Toplam Aylık Kiralama Sayısı",
      dataIndex: "totalOrderItemByMonthly",
      width: 100,
    },
    {
      key: "totalOrderItemBy3Monthly",
      title: "Toplam 3 Aylık Kiralama Sayısı",
      dataIndex: "totalOrderItemBy3Monthly",
      width: 100,
    },
    {
      key: "totalOrderItemPrice",
      title: "Toplam Tutar",
      dataIndex: "totalOrderItemPrice",
      width: 100,
    },
    {
      key: "totalOrderItemDay",
      title: "Kiralama Gün Sayısı",
      dataIndex: "totalOrderItemDay",
      width: 100,
    },
    {
      key: "totalAvailableStockDay",
      title: "Toplam Stok Gün Sayısı",
      dataIndex: "totalAvailableStockDay",
      width: 100,
    },
    // {
    //   title: "Detay",
    //   dataIndex: "",
    //   fixed: "right",
    //   key: "x",
    //   render: (_, Record) => (
    //     <NavLink to={`/Customer/${Record.id}`}>
    //       <Button type="primary">Detay</Button>
    //     </NavLink>
    //   ),
    // },
  ];

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setParams({ Size: pageSize });
    },
    onChange: (page) => {
      setParams({ Index: page });
    },
    pageSizeOptions: params.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };
  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  };
  const onFilter = (values) => {
    const queryString = '?' + new URLSearchParams(values).toString();
    navigate(queryString);
    setParams({
      ...values,
      Index: 1,
      Size: 10
    });
  };

  const _demandedExport = () => {
    notify("info");
    productSalesReportExport(params)
      .then((res) => {
        downloadFile(res);
        notify("success");
      })
      .catch(() => {
        notify("error");
      });
  };

  return (
    <>
      <Collapse>
        <Panel header="Filtreler">
          <Filters initialValues={initialValues}  onSubmit={onFilter} />
        </Panel>
      </Collapse>
      <Row>
        <Col
          style={{ padding: "24px 24px 24px 0", right: 0, marginLeft: "auto" }}
        >
          <Button
            type="primary"
            shape="round"
            icon={<DownloadOutlined />}
            onClick={_demandedExport}
          >
            Dönemsel Kiralama Raporu Export
          </Button>
        </Col>
      </Row>
      <Table
        loading={status === "pending"}
        dataSource={status === "success" ? data.data : []}
        columns={Columns}
        pagination={pagination}
        rowKey={"productName"}
      />
    </>
  );
};

export default ProductSalesReportTable;
